import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import configuration from "../Admin/configuration.json";
import co2Reduction from "../assets/CO2 reduction.png";
import coalReduction from "../assets/Coal Reduction.png";
import gasolineReduction from "../assets/Gasoline reduction.png";
import investmentImg from "../assets/investment.png";
import systemImg from "../assets/system installed.png";
import monthlyGenerationImg from "../assets/KW monthly pwer gen.png";

const schema = yup.object().shape({
  connectionType: yup.string().required("Connection Type is required"),
  calculationType: yup.string().required("Calculation Type is required"),
  tariffCategory: yup.string().required("Tariff Category is required"),

  requiredCapacityValue: yup
    .number()
    .typeError("Roof Area must be a valid number")
    .when("requiredCapacity", {
      is: "roofArea",
      then: (schema) =>
        schema
          .positive("Required capacity must be a number greater than 0")
          .required("Required capacity is required"),
    }),
  roofAreaValue: yup
    .number()
    .typeError("Roof Area must be a valid number")
    .when("calculationType", {
      is: "roofArea",
      then: (schema) =>
        schema
          .positive("Roof Area must be a number greater than 0")
          .required("Roof Area is required"),
    }),
  monthlyConsumptionValue: yup
    .number()
    .typeError("Monthly Consumption must be a valid number")
    .when("calculationType", {
      is: "monthlyConsumption",
      then: (schema) =>
        schema
          .positive("Monthly Consumption must be a number greater than 0")
          .required("Monthly Consumption is required"),
    }),
});

const Calculator = () => {
  const [systemSize, setSystemSize] = useState("");
  const [solarGenerationPer1kW, setsolarGenerationPer1kW] = useState(110);

  const [investment, setInvestment] = useState("");
  const [monthlyGeneration, setMonthlyGeneration] = useState("");
  const [reducedCarbonDioxideEmission, setReducedCarbonDioxideEmission] =
    useState("");
  const [reducedCoalQuantity, setReducedCoalQuantity] = useState("");
  const [reducedGasolineConsumption, setReducedGasolineConsumption] =
    useState("");
  const [systemSizeWarnings, setSystemSizeWarnings] = useState("");
  const [investmentWarnings, setInvestmentWarnings] = useState("");
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");
  const [minimumRoofArea, setMinimumRoofArea] = useState("");
  const [xvalueCount, setxvalueCount] = useState("");

  const retailArray = [
    "domestic",
    "domesticTOU",
    "industrial1",
    "general1",
    "government1",
    "hotel1",
    "religious1",
    "agricultural1",
  ];

  function addThousandSeparator(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const defaultValues = {
    connectionType: "1Phaseceb",
    tariffCategory: "domestic",
  };
  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  let typeOfCalculation = watch("calculationType"); // to store the user entered Calculation type
  let typeOfConnection = watch("connectionType");
  let tariffCategory = watch("tariffCategory");
  // to store the user entered Connection type

  useEffect(() => {
    setInvestment("");
    setSystemSize("");
    setMonthlyGeneration("");
    setReducedCarbonDioxideEmission("");
    setReducedCoalQuantity("");
    setReducedGasolineConsumption("");
    setSystemSizeWarnings("");
    setInvestmentWarnings("");
  }, [typeOfCalculation, typeOfConnection]);

  const calculateSystemSizeByRoofArea = (
    roofArea,
    roofAreaPer1kW,
    solarPanel
  ) => {
    const calculatedValue =
      Math.trunc(roofArea / roofAreaPer1kW / (solarPanel / 1000)) *
      (solarPanel / 1000);

    console.log(calculatedValue);

    return calculatedValue;

    // if (typeOfConnection == "1Phaseceb") {
    //   if (
    //     calculatedValue < configuration.num5PointTwoTwo ||
    //     calculatedValue > configuration.num6PointThreeEight
    //   ) {
    //     return null;
    //   } else {
    //     return calculatedValue;
    //   }
    // }

    // if (typeOfConnection == "1Phaseleco") {
    //   if (
    //     calculatedValue < configuration.num5PointTwoTwo ||
    //     calculatedValue > configuration.num7PointFiveFour
    //   ) {
    //     return null;
    //   } else {
    //     return calculatedValue;
    //   }
    // }

    // if (typeOfConnection == "3Phase") {
    //   if (calculatedValue < configuration.num5PointTwoTwo) {
    //     return null;
    //   } else {
    //     return calculatedValue;
    //   }
    // }
  };

  const calculateSystemSizeByMonthlyUsage = (
    monthlyConsumption,
    solarGenerationPer1kW,
    solarPanel
  ) => {
    const result =
      Math.ceil(
        monthlyConsumption / solarGenerationPer1kW / (solarPanel / 1000)
      ) *
      (solarPanel / 1000);
    console.log(result);

    return result;

    // if (typeOfConnection == "1Phaseceb") {
    //   if (result < configuration.num5PointTwoTwo) {
    //     return configuration.num5PointTwoTwo;
    //   } else if (result <= configuration.num6PointThreeEight) {
    //     return result;
    //   } else {
    //     // return null;
    //     return result;
    //   }
    // }

    // if (typeOfConnection == "1Phaseleco") {
    //   if (result < configuration.num5PointTwoTwo) {
    //     return configuration.num5PointTwoTwo;
    //   } else if (result <= configuration.num7PointFiveFour) {
    //     return result;
    //   } else {
    //     //return null;
    //     return result;
    //   }
    // }

    // if (typeOfConnection == "3Phase") {
    //   if (result < configuration.num5PointTwoTwo) {
    //     return configuration.num5PointTwoTwo;
    //   } else if (result >= configuration.num5PointTwoTwo) {
    //     return result;
    //   } else {
    //     //return null;
    //     return result;
    //   }
    // }
  };

  const calculateSystemSizeByRequiredCapacity = (capacity, solarPanel) => {
    const result =
      Math.ceil(capacity / (solarPanel / 1000)) * (solarPanel / 1000);
    console.log(result);
    if (typeOfConnection === "1Phaseceb") {
      if (result < configuration.num5PointTwoTwo) {
        return null;
      } else if (result <= configuration.num6PointThreeEight) {
        return result;
      } else {
        return null;
      }
    }

    if (typeOfConnection === "1Phaseleco") {
      if (result < configuration.num5PointTwoTwo) {
        return null;
      } else if (result <= configuration.num7PointFiveFour) {
        return result;
      }
    }

    if (typeOfConnection === "3Phase") {
      if (result < configuration.num5PointTwoTwo) {
        return null;
      } else if (result >= configuration.num5PointTwoTwo) {
        return result;
      }
    }
  };

  const calculateMonthlyGeneration = (systemSize, solarGenerationPer1kW) => {
    const result = Math.round(systemSize * solarGenerationPer1kW);
    return result;
  };

  const calculateInvestment = (
    systemSize,
    connectionType,
    systemCapacityRange,
    systemCapacityRangeMoreThan5kW
  ) => {
    if (typeof systemSize === "number") {
      if (
        // systemSize >= configuration.num5PointTwoTwo &&
        systemSize <= configuration.num6PointNineSix &&
        connectionType === "1Phaseceb"
      ) {
        console.log("trig1");
        //return systemCapacityRange[0].kwPrice * systemSize;
        //return systemCapacityRange[0].kwPrice * configuration.num5PointTwoTwo;  change 2
        return systemCapacityRange[0].kwPrice * systemSize;
      } else if (
        // systemSize >= configuration.num5PointTwoTwo &&
        systemSize <= configuration.num7PointFiveFour &&
        connectionType === "1Phaseleco"
      ) {
        console.log("trig mmm");
        console.log(systemSize);
        // return systemCapacityRange[0].kwPrice * systemSize;
        // return systemCapacityRange[0].kwPrice * configuration.num5PointTwoTwo;  change - 6
        return systemCapacityRange[0].kwPrice * systemSize;
      } else if (
        //systemSize > 5.22 && change-9
        systemSize >= 5.22 &&
        systemSize <= 10.44 &&
        connectionType === "3Phase"
      ) {
        console.log("trig3");

        return systemSize * systemCapacityRangeMoreThan5kW[0].per1kwPrice;
      } else if (
        systemSize > 10.44 &&
        systemSize <= 20.3 &&
        connectionType === "3Phase"
      ) {
        console.log("trig4");

        return systemSize * systemCapacityRangeMoreThan5kW[1].per1kwPrice;
      } else if (
        systemSize > 20.3 &&
        systemSize <= 30.16 &&
        connectionType === "3Phase"
      ) {
        console.log("trig5");

        return systemSize * systemCapacityRangeMoreThan5kW[2].per1kwPrice;
      } else if (
        systemSize > 30.16 &&
        systemSize <= 40.02 &&
        connectionType === "3Phase"
      ) {
        console.log("trig6");

        return systemSize * systemCapacityRangeMoreThan5kW[3].per1kwPrice;
      } else if (
        systemSize > 40.02 &&
        systemSize <= 50.46 &&
        connectionType === "3Phase"
      ) {
        console.log("trig7");

        return systemSize * systemCapacityRangeMoreThan5kW[4].per1kwPrice;
      } else if (
        systemSize > 50.46 &&
        systemSize <= 100.34 &&
        connectionType === "3Phase"
      ) {
        console.log("trig8");

        return systemSize * systemCapacityRangeMoreThan5kW[5].per1kwPrice;
      } else if (
        systemSize > 100.34 &&
        systemSize <= 150.22 &&
        connectionType === "3Phase"
      ) {
        console.log("trig9");

        return systemSize * systemCapacityRangeMoreThan5kW[6].per1kwPrice;
      } else if (
        systemSize > 150.22 &&
        systemSize <= 200.1 &&
        connectionType === "3Phase"
      ) {
        console.log("trig10");
        return systemSize * systemCapacityRangeMoreThan5kW[7].per1kwPrice;
      } else if (
        systemSize > 200.1 &&
        systemSize <= 250.56 &&
        connectionType === "3Phase"
      ) {
        console.log("trig10");
        return systemSize * systemCapacityRangeMoreThan5kW[8].per1kwPrice;
      } else if (
        systemSize > 250.56 &&
        systemSize <= 300.44 &&
        connectionType === "3Phase"
      ) {
        console.log("trig10");
        return systemSize * systemCapacityRangeMoreThan5kW[9].per1kwPrice;
      } else if (
        systemSize > 300.44 &&
        systemSize <= 350.32 &&
        connectionType === "3Phase"
      ) {
        console.log("trig10");
        return systemSize * systemCapacityRangeMoreThan5kW[10].per1kwPrice;
      } else if (
        systemSize > 350.32 &&
        systemSize <= 400.2 &&
        connectionType === "3Phase"
      ) {
        console.log("trig10");
        return systemSize * systemCapacityRangeMoreThan5kW[11].per1kwPrice;
      } else if (
        systemSize > 400.2 &&
        systemSize <= 450.08 &&
        connectionType === "3Phase"
      ) {
        console.log("trig10");
        return systemSize * systemCapacityRangeMoreThan5kW[12].per1kwPrice;
      } else if (
        systemSize > 450.08 &&
        systemSize <= 500.54 &&
        connectionType === "3Phase"
      ) {
        console.log("trig10");
        return systemSize * systemCapacityRangeMoreThan5kW[13].per1kwPrice;
      } else if (systemSize > 500.54 && connectionType === "3Phase") {
        return "Consult UTE";
      } else if (systemSize < 5.22) {
        console.log("trig before last");
        return "Capacity is not enough";
      } else {
        console.log("trig last");
        return "Need 3 Phase connection";
      }
    }
  };

  const calculateReducedCarbonDioxideEmission = (
    monthlyConsumption,
    carbonOffSet
  ) => {
    return Math.round(monthlyConsumption * carbonOffSet);
  };

  const calculateReducedCoalQuantity = (monthlyConsumption, coalOffSet) => {
    return Math.round(monthlyConsumption * coalOffSet);
  };

  const calculateReducedGasolineConsumption = (
    monthlyConsumption,
    gasolineOffset
  ) => {
    return Math.round(monthlyConsumption * gasolineOffset);
  };

  const calculateReturnPerMonth = (
    tariffCategory,
    tarrif,
    tarrifConfiguration,
    monthlyConsumptionValue
  ) => {
    let earningsPerMonth;
    console.log(tariffCategory, tarrif, tarrifConfiguration);

    if (tariffCategory === "3Phase") {
      console.log(earningsPerMonth);

      switch (tarrif) {
        case "domestic":
          earningsPerMonth =
            tarrifConfiguration.Three_Phase_CEB_LECO.domesticRetail *
            monthlyConsumptionValue;
          break;
        case "domesticTOU":
          earningsPerMonth =
            tarrifConfiguration.Three_Phase_CEB_LECO.domesticTouRetail *
            monthlyConsumptionValue;
          break;
        case "industrial1":
          earningsPerMonth =
            tarrifConfiguration.Three_Phase_CEB_LECO.industrial1Retail *
            monthlyConsumptionValue;
          break;
        case "general1":
          earningsPerMonth =
            tarrifConfiguration.Three_Phase_CEB_LECO.general1Retail *
            monthlyConsumptionValue;
          break;
        case "government1":
          earningsPerMonth =
            tarrifConfiguration.Three_Phase_CEB_LECO.government1Retail *
            monthlyConsumptionValue;
          break;
        case "hotel1":
          earningsPerMonth =
            tarrifConfiguration.Three_Phase_CEB_LECO.hotel1Retail *
            monthlyConsumptionValue;
          break;
        case "religious1":
          earningsPerMonth =
            tarrifConfiguration.Three_Phase_CEB_LECO.religious1Retail *
            monthlyConsumptionValue;
          break;
        case "agriculture1":
          earningsPerMonth =
            tarrifConfiguration.Three_Phase_CEB_LECO.agriculture1Retail *
            monthlyConsumptionValue;
          break;
        case "hotel2":
          earningsPerMonth =
            tarrifConfiguration.Three_Phase_CEB_LECO.hotel2Bulk *
            monthlyConsumptionValue;
          break;
        case "hotel3":
          earningsPerMonth =
            tarrifConfiguration.Three_Phase_CEB_LECO.hotel3Bulk *
            monthlyConsumptionValue;
          break;
        case "general2":
          earningsPerMonth =
            tarrifConfiguration.Three_Phase_CEB_LECO.general2Bulk *
            monthlyConsumptionValue;
          break;
        case "general3":
          earningsPerMonth =
            tarrifConfiguration.Three_Phase_CEB_LECO.general3Bulk *
            monthlyConsumptionValue;
          break;
        case "government2":
          earningsPerMonth =
            tarrifConfiguration.Three_Phase_CEB_LECO.government2Bulk *
            monthlyConsumptionValue;
          break;
        case "government3":
          earningsPerMonth =
            tarrifConfiguration.Three_Phase_CEB_LECO.government3Bulk *
            monthlyConsumptionValue;
          break;
        case "industrial2":
          earningsPerMonth =
            tarrifConfiguration.Three_Phase_CEB_LECO.industrial2Bulk *
            monthlyConsumptionValue;
          break;
        case "industrial3":
          earningsPerMonth =
            tarrifConfiguration.Three_Phase_CEB_LECO.industrial3Bulk *
            monthlyConsumptionValue;
          break;
      }
    }

    if (tariffCategory === "1Phaseceb") {
      switch (tarrif) {
        case "domestic":
          earningsPerMonth =
            tarrifConfiguration.Single_Phase_CEB.domesticRetail *
            monthlyConsumptionValue;
          break;
        case "domesticTOU":
          earningsPerMonth =
            tarrifConfiguration.Single_Phase_CEB.domesticTouRetail *
            monthlyConsumptionValue;
          break;
        case "industrial1":
          earningsPerMonth =
            tarrifConfiguration.Single_Phase_CEB.industrial1Retail *
            monthlyConsumptionValue;
          break;
        case "general1":
          earningsPerMonth =
            tarrifConfiguration.Single_Phase_CEB.general1Retail *
            monthlyConsumptionValue;
          break;
        case "government1":
          earningsPerMonth =
            tarrifConfiguration.Single_Phase_CEB.government1Retail *
            monthlyConsumptionValue;
          break;
        case "hotel1":
          earningsPerMonth =
            tarrifConfiguration.Single_Phase_CEB.hotel1Retail *
            monthlyConsumptionValue;
          break;
        case "religious1":
          earningsPerMonth =
            tarrifConfiguration.Single_Phase_CEB.religious1Retail *
            monthlyConsumptionValue;
          break;
        case "agriculture1":
          earningsPerMonth =
            tarrifConfiguration.Single_Phase_CEB.agriculture1Retail *
            monthlyConsumptionValue;
          break;
      }
    }

    if (tariffCategory === "1Phaseleco") {
      switch (tarrif) {
        case "domestic":
          earningsPerMonth =
            tarrifConfiguration.Single_Phase_LECO.domesticRetail *
            monthlyConsumptionValue;
          break;
        case "domesticTOU":
          earningsPerMonth =
            tarrifConfiguration.Single_Phase_LECO.domesticTouRetail *
            monthlyConsumptionValue;
          break;
        case "industrial1":
          earningsPerMonth =
            tarrifConfiguration.Single_Phase_LECO.industrial1Retail *
            monthlyConsumptionValue;
          break;
        case "general1":
          earningsPerMonth =
            tarrifConfiguration.Single_Phase_LECO.general1Retail *
            monthlyConsumptionValue;
          break;
        case "government1":
          earningsPerMonth =
            tarrifConfiguration.Single_Phase_LECO.government1Retail *
            monthlyConsumptionValue;
          break;
        case "hotel1":
          earningsPerMonth =
            tarrifConfiguration.Single_Phase_LECO.hotel1Retail *
            monthlyConsumptionValue;
          break;
        case "religious1":
          earningsPerMonth =
            tarrifConfiguration.Single_Phase_LECO.religious1Retail *
            monthlyConsumptionValue;
          break;
        case "agriculture1":
          earningsPerMonth =
            tarrifConfiguration.Single_Phase_LECO.agriculture1Retail *
            monthlyConsumptionValue;
          break;
      }
    }
    return earningsPerMonth;
  };
  //SOHAN
  const calculateMinimumRequiredRoofArea = (systemSize, perRoofArea) => {
    const reqroof = (systemSize * perRoofArea) / 10;
    return Math.round(reqroof) * 10;
  };

  //INPUTVAL = value of the input feild
  const calculateNewFunction = (inputval, parameterfor1kw, solarpanel) => {
    const xvalue =
      Math.trunc(inputval / parameterfor1kw / (solarpanel / 1000)) *
      (solarpanel / 1000);
    return xvalue;
  };

  const calculateNewFunction2 = (inputval, solarpanel) => {
    const xvalue2 =
      Math.trunc(inputval / (solarpanel / 1000)) * (solarpanel / 1000);
    return xvalue2;
  };

  //SOHAN OVER

  const calculateValues = (data) => {
    let systemSize;
    let monthlyGeneration;
    let investment;
    let reducedCarbonDioxideEmission;
    let reducedCoalQuantity;
    let reducedGasolineConsumption;
    let monthlyReturn;
    let numberOfMonthsToCover;
    let numberOfYears;
    let numberOfMonths;
    let minimumRoofArea;
    let xvalueCount;

    //SOHAN
    if (data.calculationType === "requiredCapacity") {
      systemSize = calculateSystemSizeByRequiredCapacity(
        data.requiredCapacityValue,
        configuration.solarPanel
      );

      // change -6 xvalue hoist
      // xvalueCount = calculateNewFunction2(
      //   data.requiredCapacityValue,
      //   configuration.solarPanel
      // );

      // console.log(data.requiredCapacityValue);
      // console.log(configuration.solarPanel);
      // console.log("xValue in required capacity", xvalueCount);

      //change 8
      console.log("size", systemSize);
      xvalueCount = data.requiredCapacityValue;
      setxvalueCount(xvalueCount);

      console.log("xValue after setter", xvalueCount);

      console.log("required capacity sections", systemSize);

      if (systemSize > 0) {
        monthlyGeneration = calculateMonthlyGeneration(
          systemSize,
          configuration.solarGenerationPer1kW
        );
        console.log(monthlyGeneration);

        investment = calculateInvestment(
          systemSize,
          data.connectionType,
          configuration.systemCapacityRange,
          configuration.systemCapacityRangeMoreThan5kW
        );

        console.log(investment);

        //SOHAN
        minimumRoofArea = calculateMinimumRequiredRoofArea(
          systemSize,
          configuration.roofAreaPer1kW
        );
        console.log("MINIMUM ROOF", minimumRoofArea);

        //SOHAN OVER

        reducedCarbonDioxideEmission = calculateReducedCarbonDioxideEmission(
          monthlyGeneration,
          configuration.environmentalOffsets.carbonDioxide
        );
        reducedCoalQuantity = calculateReducedCoalQuantity(
          monthlyGeneration,
          configuration.environmentalOffsets.coal
        );
        reducedGasolineConsumption = calculateReducedGasolineConsumption(
          monthlyGeneration,
          configuration.environmentalOffsets.gasoline
        );
        if (investment > 0) {
          monthlyReturn = calculateReturnPerMonth(
            data.connectionType,
            data.tariffCategory,
            configuration.tarrif,
            monthlyGeneration
          );

          numberOfMonthsToCover = Math.ceil(investment / monthlyReturn);
          numberOfYears = Math.floor(numberOfMonthsToCover / 12);
          numberOfMonths = numberOfMonthsToCover % 12;
          setMonth(numberOfMonths);
          setYear(numberOfYears);
        }

        setMinimumRoofArea(minimumRoofArea + " Sqft");

        setSystemSize(systemSize.toFixed(2) + " kWp");

        setMonthlyGeneration(Math.round(monthlyGeneration) + " Units");

        setReducedCarbonDioxideEmission(
          reducedCarbonDioxideEmission.toFixed(0) + "  Kg"
        );
        setReducedCoalQuantity(reducedCoalQuantity.toFixed(0) + "  Kg");
        setReducedGasolineConsumption(
          reducedGasolineConsumption.toFixed(0) + "  L"
        );

        if (investment > 0) {
          setInvestment(
            "LKR " +
              addThousandSeparator(investment.toFixed(0)) +
              ".00  + 18% VAT"
          );
        } else {
          // setInvestmentWarnings(investment);
          setInvestment(investment);
        }

        return;
      }
      setSystemSizeWarnings(systemSize);
    }

    //SOHAN OVER
    if (data.calculationType === "roofArea") {
      systemSize = calculateSystemSizeByRoofArea(
        data.roofAreaValue,
        configuration.roofAreaPer1kW,
        configuration.solarPanel
      );
      console.log("hi", systemSize);

      //change 3  - hoist xvalue count to top
      xvalueCount = calculateNewFunction(
        data.roofAreaValue,
        configuration.roofAreaPer1kW,
        configuration.solarPanel
      );

      console.log(data.roofAreaValue);
      console.log(configuration.roofAreaPer1kW);
      console.log(configuration.solarPanel);
      console.log("xValue in roof area", xvalueCount);

      setxvalueCount(xvalueCount);

      console.log("after setter xvalue", xvalueCount);

      if (data.connectionType === "1Phaseceb") {
        if (systemSize < configuration.num5PointTwoTwo) {
          //setSystemSize(null); change 1
          systemSize = null;
        } else if (systemSize <= configuration.num6PointThreeEight) {
          setSystemSize(systemSize);
        } else {
          systemSize = null;
        }
        console.log("1phaseceb", systemSize);
      }

      if (data.connectionType === "1Phaseleco") {
        if (systemSize < configuration.num5PointTwoTwo) {
          systemSize = null;
        } else if (systemSize <= configuration.num7PointFiveFour) {
          setSystemSize(systemSize);
        } else {
          systemSize = null;
        }
        console.log("1phaseleco", systemSize);
      }

      if (data.connectionType === "3Phase") {
        if (systemSize < configuration.num5PointTwoTwo) {
          //setSystemSize(null); change -9
          systemSize = null;
        } else {
          console.log("3Phase", systemSize);
          setSystemSize(systemSize);
        }
      }

      console.log("outer check", systemSize);
      if (systemSize > 0) {
        monthlyGeneration = calculateMonthlyGeneration(
          systemSize,
          configuration.solarGenerationPer1kW
        );
        console.log(monthlyGeneration);

        investment = calculateInvestment(
          systemSize,
          data.connectionType,
          configuration.systemCapacityRange,
          configuration.systemCapacityRangeMoreThan5kW
        );

        console.log(investment);

        //change

        //SOHAN

        minimumRoofArea = calculateMinimumRequiredRoofArea(
          systemSize,
          configuration.roofAreaPer1kW
        );
        console.log("MINIMUM ROOF", minimumRoofArea);

        //SOHAN OVER

        reducedCarbonDioxideEmission = calculateReducedCarbonDioxideEmission(
          monthlyGeneration,
          configuration.environmentalOffsets.carbonDioxide
        );
        reducedCoalQuantity = calculateReducedCoalQuantity(
          monthlyGeneration,
          configuration.environmentalOffsets.coal
        );
        reducedGasolineConsumption = calculateReducedGasolineConsumption(
          monthlyGeneration,
          configuration.environmentalOffsets.gasoline
        );
        if (investment > 0) {
          monthlyReturn = calculateReturnPerMonth(
            data.connectionType,
            data.tariffCategory,
            configuration.tarrif,
            monthlyGeneration
          );

          numberOfMonthsToCover = Math.ceil(investment / monthlyReturn);
          numberOfYears = Math.floor(numberOfMonthsToCover / 12);
          numberOfMonths = numberOfMonthsToCover % 12;

          setMonth(numberOfMonths);
          setYear(numberOfYears);
        }

        setMinimumRoofArea(minimumRoofArea + " Sqft");

        setSystemSize(systemSize.toFixed(2) + " kWp");
        setMonthlyGeneration(Math.round(monthlyGeneration) + " Units");
        setMinimumRoofArea(minimumRoofArea + " Sqft");

        if (investment > 0) {
          setInvestment(
            "LKR " +
              addThousandSeparator(investment.toFixed(0)) +
              ".00  + 18% VAT"
          );
          setMonthlyGeneration(Math.round(monthlyGeneration) + " Units");

          setReducedCarbonDioxideEmission(
            reducedCarbonDioxideEmission.toFixed(0) + "  Kg"
          );
          setReducedCoalQuantity(reducedCoalQuantity.toFixed(0) + "  Kg");
          setReducedGasolineConsumption(
            reducedGasolineConsumption.toFixed(0) + "  L"
          );
        } else {
          // setInvestmentWarnings(investment);
          setInvestment(investment);
        }

        return;
      }
      setSystemSizeWarnings(systemSize);
      console.log(investment);
    }

    // if (data.calculationType === "monthlyConsumption") {
    //   systemSize = calculateSystemSizeByMonthlyUsage(
    //     data.monthlyConsumptionValue,
    //     configuration.solarGenerationPer1kW,
    //     configuration.solarPanel
    //   );
    //   console.log("system size", systemSize);

    //   if (systemSize > 0) {
    //     monthlyGeneration = calculateMonthlyGeneration(
    //       systemSize,
    //       configuration.solarGenerationPer1kW
    //     );

    //     if (systemSize < configuration.num5PointTwoTwo) {
    //       systemSize = configuration.num5PointTwoTwo;
    //       console.log("system size conversion", systemSize);
    //     }
    //     console.log("system size", systemSize);

    //     investment = calculateInvestment(
    //       systemSize,
    //       data.connectionType,
    //       configuration.systemCapacityRange,
    //       configuration.systemCapacityRangeMoreThan5kW
    //     );
    //     console.log("investment --", investment, typeof investment);

    //     reducedCarbonDioxideEmission = calculateReducedCarbonDioxideEmission(
    //       monthlyGeneration,
    //       configuration.environmentalOffsets.carbonDioxide
    //     );
    //     reducedCoalQuantity = calculateReducedCoalQuantity(
    //       monthlyGeneration,
    //       configuration.environmentalOffsets.coal
    //     );
    //     reducedGasolineConsumption = calculateReducedGasolineConsumption(
    //       monthlyGeneration,
    //       configuration.environmentalOffsets.gasoline
    //     );

    //     //SOHAN
    //     minimumRoofArea = calculateMinimumRequiredRoofArea(
    //       systemSize,
    //       configuration.roofAreaPer1kW
    //     );
    //     console.log("MINIMUM ROOF", minimumRoofArea);

    //     xvalueCount = calculateNewFunction(
    //       data.monthlyConsumptionValue,
    //       configuration.solarGenerationper1kWSystemCalc,
    //       configuration.solarPanel
    //     );

    //     console.log(data.monthlyConsumptionValue);
    //     console.log(configuration.solarGenerationper1kWSystemCalc);
    //     console.log(configuration.solarPanel);
    //     console.log("xValue in monthly area", xvalueCount);

    //     //SOHAN OVER

    //     if (investment > 0) {
    //       monthlyReturn = calculateReturnPerMonth(
    //         data.connectionType,
    //         data.tariffCategory,
    //         configuration.tarrif,
    //         monthlyGeneration
    //       );

    //       numberOfMonthsToCover = (investment / monthlyReturn).toFixed(0);
    //       numberOfYears = Math.floor(numberOfMonthsToCover / 12);
    //       numberOfMonths = numberOfMonthsToCover % 12;
    //       setMonth(numberOfMonths);
    //       setYear(numberOfYears);
    //     }

    //     //SOHAN
    //     setxvalueCount(xvalueCount);

    //     setMinimumRoofArea(minimumRoofArea + " Sqft");
    //     //SOHAN OVER

    //     console.log("system size check 1", systemSize);

    //     setSystemSize(systemSize.toFixed(2) + " kW");

    //     if (investment > 0) {
    //       setInvestment("LKR " + investment.toFixed(0) + " + 18% VAT");
    //       setMonthlyGeneration(
    //         Math.round(systemSize.toFixed(2) * solarGenerationPer1kW) + " Units"
    //       );

    //       //setMonthlyGeneration(data.monthlyConsumptionValue.toFixed(2) + " Units");

    //       // if (systemSize <= configuration.num5PointTwoTwo) {
    //       //   setMonthlyGeneration(
    //       //     Math.round(configuration.num5PointTwoTwo * solarGenerationPer1kW) +
    //       //       "  units"
    //       //   );
    //       // } else {
    //       //   setMonthlyGeneration(
    //       //     systemSize.toFixed(2) * solarGenerationPer1kW + " Units"
    //       //   );
    //       // }
    //       console.log("system size check", systemSize);

    //       setReducedCarbonDioxideEmission(
    //         reducedCarbonDioxideEmission.toFixed(0) + "  Kg"
    //       );
    //       setReducedCoalQuantity(reducedCoalQuantity.toFixed(0) + "  Kg");
    //       setReducedGasolineConsumption(
    //         reducedGasolineConsumption.toFixed(0) + "  L"
    //       );
    //     } else {
    //       // setInvestmentWarnings(investment);
    //       setInvestment(null);
    //       setSystemSize(null);
    //       setMonthlyGeneration(null);
    //       setMinimumRoofArea(null);
    //     }

    //     return;
    //   }

    // }

    if (data.calculationType === "monthlyConsumption") {
      systemSize = calculateSystemSizeByMonthlyUsage(
        data.monthlyConsumptionValue,
        configuration.solarGenerationper1kWSystemCalc,
        configuration.solarPanel
      );

      console.log("system size pre", systemSize);
      //change -5  xvalue hoist
      xvalueCount = calculateNewFunction(
        data.monthlyConsumptionValue,
        configuration.solarGenerationper1kWSystemCalc,
        configuration.solarPanel
      );

      console.log(data.monthlyConsumptionValue);
      console.log(configuration.solarGenerationper1kWSystemCalc);
      console.log(configuration.solarPanel);
      console.log("xValue in monthly area", xvalueCount);

      setxvalueCount(xvalueCount);

      console.log("after setter xvalue", xvalueCount);

      if (systemSize < configuration.num5PointTwoTwo) {
        //setSystemSize("5.22"); change - 4
        systemSize = configuration.num5PointTwoTwo;
      } else if (systemSize <= configuration.num6PointThreeEight) {
        setSystemSize(systemSize);
      }

      // else if (systemSize > configuration.num6PointThreeEight) {
      //   if (data.connectionType !== "3Phase") {
      //     console.log("chek");
      //     systemSize = null;
      //   } else {
      //     setSystemSize(systemSize);
      //   }
      //   console.log("system size greater than 6.38", systemSize);
      // }

      //check - 7
      else if (systemSize > configuration.num6PointThreeEight) {
        if (
          data.connectionType === "1Phaseceb" &&
          systemSize <= configuration.num6PointNineSix
        ) {
          setSystemSize(systemSize);
          console.log(
            "system size greater than 6.38 and less tham 6.94 with ceb single phase",
            systemSize
          );
        } else if (
          data.connectionType === "1Phaseleco" &&
          systemSize <= configuration.num7PointFiveFour
        ) {
          setSystemSize(systemSize);
          console.log(
            "system size greater than 6.38 and less tham 7.54 with leco",
            systemSize
          );
        } else if (data.connectionType === "3Phase") {
          setSystemSize(systemSize);
          console.log("system size greater than 6.38 and 3 phase", systemSize);
        } else {
          systemSize = null;
        }
      }

      if (systemSize > 0) {
        monthlyGeneration = calculateMonthlyGeneration(
          systemSize,
          configuration.solarGenerationPer1kW
        );

        console.log("system size outer", systemSize);

        investment = calculateInvestment(
          systemSize,
          data.connectionType,
          configuration.systemCapacityRange,
          configuration.systemCapacityRangeMoreThan5kW
        );
        console.log("investment --", investment, typeof investment);

        reducedCarbonDioxideEmission = calculateReducedCarbonDioxideEmission(
          monthlyGeneration,
          configuration.environmentalOffsets.carbonDioxide
        );
        reducedCoalQuantity = calculateReducedCoalQuantity(
          monthlyGeneration,
          configuration.environmentalOffsets.coal
        );
        reducedGasolineConsumption = calculateReducedGasolineConsumption(
          monthlyGeneration,
          configuration.environmentalOffsets.gasoline
        );

        //SOHAN
        minimumRoofArea = calculateMinimumRequiredRoofArea(
          systemSize,
          configuration.roofAreaPer1kW
        );
        console.log("MINIMUM ROOF", minimumRoofArea);

        //SOHAN OVER

        if (investment > 0) {
          monthlyReturn = calculateReturnPerMonth(
            data.connectionType,
            data.tariffCategory,
            configuration.tarrif,
            monthlyGeneration
          );

          numberOfMonthsToCover = Math.ceil(investment / monthlyReturn);
          numberOfYears = Math.floor(numberOfMonthsToCover / 12);
          numberOfMonths = numberOfMonthsToCover % 12;
          setMonth(numberOfMonths);
          setYear(numberOfYears);
        }

        //SOHAN

        setMinimumRoofArea(minimumRoofArea + " Sqft");
        //SOHAN OVER

        console.log("system size check 1", systemSize);

        setSystemSize(systemSize.toFixed(2) + " kWp");

        if (systemSize <= 500.54) {
          if (investment > 0) {
            setInvestment(
              "LKR " +
                addThousandSeparator(investment.toFixed(0)) +
                ".00  + 18% VAT"
            );
            setMonthlyGeneration(
              Math.round(systemSize.toFixed(2) * solarGenerationPer1kW) +
                " Units"
            );

            //setMonthlyGeneration(data.monthlyConsumptionValue.toFixed(2) + " Units");

            // if (systemSize <= configuration.num5PointTwoTwo) {
            //   setMonthlyGeneration(
            //     Math.round(configuration.num5PointTwoTwo * solarGenerationPer1kW) +
            //       "  units"
            //   );
            // } else {
            //   setMonthlyGeneration(
            //     systemSize.toFixed(2) * solarGenerationPer1kW + " Units"
            //   );
            // }
            console.log("system size check", systemSize);

            setReducedCarbonDioxideEmission(
              reducedCarbonDioxideEmission.toFixed(0) + "  Kg"
            );
            setReducedCoalQuantity(reducedCoalQuantity.toFixed(0) + "  Kg");
            setReducedGasolineConsumption(
              reducedGasolineConsumption.toFixed(0) + "  L"
            );
          } else {
            // setInvestmentWarnings(investment);
            setInvestment(null);
            setSystemSize(null);
            setMonthlyGeneration(null);
            setMinimumRoofArea(null);
          }
        } else {
          setInvestment(investment);
          setSystemSize(systemSize.toFixed(2) + " kWp");
          setMonthlyGeneration(
            Math.round(systemSize.toFixed(2) * solarGenerationPer1kW) + " Units"
          );
          setMinimumRoofArea(minimumRoofArea + " Sqft");
          setReducedCarbonDioxideEmission(
            reducedCarbonDioxideEmission.toFixed(0) + "  Kg"
          );
          setReducedCoalQuantity(reducedCoalQuantity.toFixed(0) + "  Kg");
          setReducedGasolineConsumption(
            reducedGasolineConsumption.toFixed(0) + "  L"
          );
        }
        return;
      }
    }
  };

  const onSubmit = (data) => {
    setSystemSizeWarnings("");
    setInvestmentWarnings("");
    setSystemSize("");
    setInvestment("");
    setMonthlyGeneration("");
    setReducedCarbonDioxideEmission("");
    setReducedCoalQuantity("");
    setReducedGasolineConsumption("");
    setMonth("");
    setYear("");
    setMinimumRoofArea("");
    calculateValues(data);
    console.log(data);
  };

  return (
    <div className="wrapper">
      <div className="bodyContent">
        <div className="calculator">
          <div className="calculatorArea">
            <h2 className="title">Solar Power Calculator</h2>
            <p className="instruction">
              Fill in the details about your property and the type of solar
              power system you are seeking.
            </p>
            <div>
              <h3 className="enterDetails">Enter Your Details</h3>
            </div>
            <div className="formArea">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="formLine">
                  <label htmlFor="connectionType">Connection Type</label>
                  <Controller
                    name="connectionType"
                    control={control}
                    render={({ field }) => (
                      <select {...field}>
                        <option value="1Phaseceb">Single Phase CEB</option>
                        <option value="1Phaseleco">Single Phase LECO</option>
                        <option value="3Phase">Three Phase CEB_LECO</option>
                      </select>
                    )}
                  />
                  <p>{errors.connectionType?.message}</p>
                </div>

                <div className="formLine">
                  <label htmlFor="tariffCategory">Tariff Category</label>
                  <Controller
                    name="tariffCategory"
                    control={control}
                    render={({ field }) => (
                      <select {...field} className="dropdown">
                        <option value="domestic">Domestic (Retail)</option>
                        <option value="domesticTOU">
                          Domestic TOU (Retail)
                        </option>
                        <option value="industrial1">
                          Industrial-1 (Retail)
                        </option>
                        <option value="general1">General-1 (Retail)</option>
                        <option value="government1">
                          Government-1 (Retail)
                        </option>
                        <option value="hotel1">Hotel-1 (Retail)</option>
                        <option value="religious1">Religious-1 (Retail)</option>
                        <option value="agriculture1">
                          Agriculture-1 (Retail)
                        </option>
                        {typeOfConnection === "3Phase" && (
                          <>
                            <option value="hotel2">Hotel-2 (Bulk)</option>
                            <option value="hotel3">Hotel-3 (Bulk)</option>

                            <option value="general2">General-2 (Bulk)</option>
                            <option value="general3">General-3 (Bulk)</option>
                            <option value="government2">
                              Government-2 (Bulk)
                            </option>
                            <option value="government3">
                              Government-3 (Bulk)
                            </option>
                            <option value="industrial2">
                              Industrial-2 (Bulk)
                            </option>
                            <option value="industrial3">
                              Industrial-3 (Bulk)
                            </option>
                          </>
                        )}
                      </select>
                    )}
                  />
                  <p>{errors.tariffCategory?.message}</p>
                </div>

                <div className="formLine">
                  <label htmlFor="calculationType">What you know</label>
                  <div className="input">
                    <Controller
                      name="calculationType"
                      control={control}
                      render={({ field }) => (
                        <select {...field}>
                          <option value="" disabled selected>
                            Select an option
                          </option>
                          <option value="roofArea">Roof Area</option>
                          <option value="monthlyConsumption">
                            Monthly Consumption
                          </option>
                          {/*SOHAN*/}
                          <option value="requiredCapacity">
                            Required Capacity
                          </option>
                          {/*SOHAN OVER*/}
                        </select>
                      )}
                    />
                    <p>{errors.calculationType?.message}</p>
                  </div>
                </div>

                <div className="formLine">
                  {typeOfCalculation === "roofArea" && (
                    <div>
                      <label htmlFor="roofAreaValue">Roof Area</label>
                      <div className="input">
                        <Controller
                          name="roofAreaValue"
                          control={control}
                          render={({ field }) => (
                            <input
                              type="text"
                              placeholder="Enter Square Feet Value"
                              {...field}
                            />
                          )}
                        />
                        <p>{errors.roofAreaValue?.message}</p>
                      </div>
                    </div>
                  )}

                  {typeOfCalculation === "monthlyConsumption" && (
                    <div>
                      <label htmlFor="monthlyConsumptionValue">
                        Monthly Consumption
                      </label>
                      <div className="input">
                        <Controller
                          name="monthlyConsumptionValue"
                          control={control}
                          render={({ field }) => (
                            <input
                              type="text"
                              placeholder="Enter number of Units per month"
                              {...field}
                            />
                          )}
                        />
                        <p>{errors.monthlyConsumptionValue?.message}</p>
                      </div>
                    </div>
                  )}

                  {/*SOHAN */}
                  {typeOfCalculation === "requiredCapacity" && (
                    <div>
                      <label htmlFor="requiredCapacityValue">
                        Required Capacity
                      </label>
                      <div className="input">
                        <Controller
                          name="requiredCapacityValue"
                          control={control}
                          render={({ field }) => (
                            <input
                              type="text"
                              placeholder="Enter Capacity"
                              {...field}
                            />
                          )}
                        />
                        <p>{errors.requiredcapaCityValue?.message}</p>
                      </div>
                    </div>
                  )}
                  {/*SOHAN OVER*/}
                </div>

                <button type="submit">Calculate </button>
              </form>
            </div>
          </div>
        </div>

        <div className="results">
          <div className="resultsArea">
            <div className="importantResult">
              <h2>Your Solar Calculator Results</h2>
              <div className="impResultImageSection">
                <div className="card">
                  <img src={systemImg} alt="System Size" />

                  <p>
                    System Capacity
                    <br />
                    {/* {typeOfCalculation === 'monthlyConsumption'
                      ? 'to zero electricity bill'
                      : ''}{' '} */}
                    <br />
                    {systemSizeWarnings === "" ? (
                      <b>{systemSize}</b>
                    ) : (
                      <b className="warnings">{systemSizeWarnings}</b>
                    )}
                  </p>
                </div>
                <div className="card">
                  <img src={investmentImg} alt="Investment" />

                  <p>
                    Investment* <br />
                    <br />
                    {investmentWarnings === "" ? (
                      //<b>{investment}</b> change -10
                      <b
                        style={{
                          color:
                            investment.includes("Consult UTE") ||
                            investment.includes("Capacity is not enough") ||
                            investment.includes("Need 3 Phase connection")
                              ? "red"
                              : "#04935f",
                        }}
                      >
                        {investment}
                      </b>
                    ) : (
                      <b className="warnings">{investmentWarnings}</b>
                    )}
                  </p>
                </div>
                <div className="card">
                  <img src={monthlyGenerationImg} alt="Monthly Generation" />

                  <p>
                    Monthly Generation <br />
                    <br />
                    <b>{monthlyGeneration} </b>
                  </p>
                </div>
              </div>

              <div className="paybackArea">
                {year || month ? (
                  <div className="msgboxmodel yearsandmonths">
                    <p>
                      Your Investment will be returned in less than
                      {year && (
                        <>
                          <b>{" " + year + " "} </b>years
                        </>
                      )}
                      {year && month > 0 && <>{" and "}</>}
                      {month > 0 && (
                        <>
                          <b>{" " + month + " "} </b>months
                        </>
                      )}
                    </p>
                  </div>
                ) : null}

                {/*SOHAN FINAL*/}
                {/*Roof area 1 */}
                {typeOfCalculation === "roofArea" &&
                  typeOfConnection === "1Phaseceb" &&
                  xvalueCount !== "" &&
                  (xvalueCount < configuration.num5PointTwoTwo ? (
                    <div className="msgboxmodel">
                      You need more roof to install minimum available capacity
                      of {configuration.num5PointTwoTwo} kWp
                    </div>
                  ) : xvalueCount <=
                    configuration.num6PointThreeEight ? null : (
                    <div className="msgboxmodel">
                      You need to have a three-phase bulk connection to install
                      the capacity that matches the specified area.
                    </div>
                  ))}

                {/*Roof area 2 */}
                {typeOfCalculation === "roofArea" &&
                  typeOfConnection === "1Phaseleco" &&
                  xvalueCount !== "" &&
                  (xvalueCount < configuration.num5PointTwoTwo ? (
                    <div className="msgboxmodel">
                      You need more roof to install minimum available capacity
                      of {configuration.num5PointTwoTwo} kWp
                    </div>
                  ) : xvalueCount <= configuration.num7PointFiveFour ? null : (
                    <div className="msgboxmodel">
                      You need to have a three-phase bulk connection to install
                      the capacity that matches the specified area.
                    </div>
                  ))}

                {/*Roof area 3 */}
                {typeOfCalculation === "roofArea" &&
                  typeOfConnection === "3Phase" &&
                  xvalueCount !== "" &&
                  (xvalueCount < configuration.num5PointTwoTwo ? (
                    <div className="msgboxmodel">
                      You need more roof to install minimum available capacity
                      of {configuration.num5PointTwoTwo} kWp
                    </div>
                  ) : xvalueCount >= configuration.num5PointTwoTwo &&
                    xvalueCount <=
                      configuration.num48PointSevenTwo ? null : xvalueCount >
                      configuration.num48PointSevenTwo &&
                    retailArray.includes(tariffCategory) ? (
                    <div className="msgboxmodel">
                      You need to have a three-phase bulk connection to install
                      the capacity that matches the specified area.
                    </div>
                  ) : null)}

                {/*monthly area 1 */}
                {typeOfCalculation === "monthlyConsumption" &&
                  typeOfConnection === "1Phaseceb" &&
                  xvalueCount !== "" &&
                  (xvalueCount < configuration.num5PointTwoTwo ? (
                    <div className="msgboxmodel">
                      Available minimum capacity is {""}
                      {configuration.num5PointTwoTwo} kWp for your connection
                      type
                    </div>
                  ) : xvalueCount <=
                    configuration.num6PointThreeEight ? null : xvalueCount >
                    configuration.num6PointThreeEight ? (
                    <div className="msgboxmodel">
                      You need to have a three-phase connection to install the
                      capacity that matches the specified monthly consumption.
                    </div>
                  ) : null)}

                {/*monthly area 2 */}
                {typeOfCalculation === "monthlyConsumption" &&
                  typeOfConnection === "1Phaseleco" &&
                  xvalueCount !== "" &&
                  (xvalueCount < configuration.num5PointTwoTwo ? (
                    <div className="msgboxmodel">
                      Available minimum capacity is {""}
                      {configuration.num5PointTwoTwo} kWp for your connection
                      type
                    </div>
                  ) : xvalueCount <= configuration.num7PointFiveFour ? null : (
                    <div className="msgboxmodel">
                      You need to have a three-phase connection to install the
                      capacity that matches the specified monthly consumption.
                    </div>
                  ))}

                {/*monthly area 3 */}
                {typeOfCalculation === "monthlyConsumption" &&
                  typeOfConnection === "3Phase" &&
                  xvalueCount !== "" &&
                  (xvalueCount < configuration.num5PointTwoTwo ? (
                    <div className="msgboxmodel">
                      Available minimum capacity is{" "}
                      {configuration.num5PointTwoTwo} kWp for your connection
                      type
                    </div>
                  ) : xvalueCount >= configuration.num5PointTwoTwo &&
                    xvalueCount <=
                      configuration.num48PointSevenTwo ? null : xvalueCount >
                      configuration.num48PointSevenTwo &&
                    retailArray.includes(tariffCategory) ? (
                    <div className="msgboxmodel">
                      You need to have a three-phase bulk connection to install
                      the capacity that matches the specified monthly
                      consumption.
                    </div>
                  ) : null)}

                {/*required capacity 1 */}
                {typeOfCalculation === "requiredCapacity" &&
                  typeOfConnection === "1Phaseceb" &&
                  xvalueCount !== "" &&
                  (xvalueCount < configuration.num5PointTwoTwo ? (
                    <div className="msgboxmodel">
                      Available minimum capacity is {""}
                      {configuration.num5PointTwoTwo} kWp for your connection
                      type
                    </div>
                  ) : xvalueCount <=
                    configuration.num6PointThreeEight ? null : (
                    <div className="msgboxmodel">
                      Available maximum capacity is{" "}
                      {configuration.num6PointThreeEight} kWp for your
                      connection type
                    </div>
                  ))}

                {/*required capacity 2*/}
                {typeOfCalculation === "requiredCapacity" &&
                  typeOfConnection === "1Phaseleco" &&
                  xvalueCount !== "" &&
                  (xvalueCount < configuration.num5PointTwoTwo ? (
                    <div className="msgboxmodel">
                      Available minimum capacity is {""}
                      {configuration.num5PointTwoTwo} kWp for your connection
                      type
                    </div>
                  ) : xvalueCount <= configuration.num7PointFiveFour ? null : (
                    <div className="msgboxmodel">
                      Available maximum capacity is{" "}
                      {configuration.num7PointFiveFour} kWp for your connection
                      type.
                    </div>
                  ))}

                {/*required capacity 3 */}
                {typeOfCalculation === "requiredCapacity" &&
                  typeOfConnection === "3Phase" &&
                  xvalueCount !== "" &&
                  (xvalueCount < configuration.num5PointTwoTwo ? (
                    <div className="msgboxmodel">
                      Available minimum capacity is{" "}
                      {configuration.num5PointTwoTwo} kWp for your connection
                      type
                    </div>
                  ) : xvalueCount > configuration.num5PointTwoTwo &&
                    xvalueCount <=
                      configuration.num48PointSevenTwo ? null : xvalueCount >
                      configuration.num48PointSevenTwo &&
                    retailArray.includes(tariffCategory) ? (
                    <div className="msgboxmodel">
                      You need to have a three-phase bulk connection to install
                      the capacity that matches the specified monthly
                      consumption.
                    </div>
                  ) : null)}

                {/* Bottom araea */}

                {minimumRoofArea && (
                  <div className="msgboxmodel roofaraea">
                    Minimum Required Roof Area {minimumRoofArea}
                  </div>
                )}
                {/*SOHAN FINAL OVER */}
              </div>
            </div>

            <div className="offsets">
              <h3>Environmental Offsets</h3>
              <h4>You will be contributed monthly to the environment by</h4>
              <div className="offsetImageSection">
                <div className="cardTwo">
                  <img src={co2Reduction} alt="CO2reduction" />
                  <p>
                    Reducing Carbon <br />
                    Dioxide emission <br />
                    <br />
                    <b>{reducedCarbonDioxideEmission}</b>
                  </p>
                </div>
                <div className="cardTwo">
                  <img src={coalReduction} alt="CO2reduction" />

                  <p>
                    Reducing Coal <br /> Burning of <br />
                    <br />
                    <b>{reducedCoalQuantity}</b>
                  </p>
                </div>
                <div className="cardTwo">
                  <img src={gasolineReduction} alt="CO2reduction" />
                  <p>
                    Reducing Gasoline <br /> Consumption of <br />
                    <br />
                    <b>{reducedGasolineConsumption}</b>
                  </p>
                </div>
              </div>
            </div>

            <p>*Conditions Apply </p>

            <div className="quotationSection">
              <div className="quotationSectionArea">
                <div className="paraArea">
                  <div className="firstPara">GET A QUOTE FOR THIS SYSTEM</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Calculator;
